














































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import controller from '@/app/ui/controllers/PayrollController'
import { Withdraw } from '@/domain/entities/Payroll'
import { LocalStorage } from '@/app/infrastructures/misc'
import ModalConfirm from '../../../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../../../components/Modals/ModalSuccess/index.vue'

interface PeriodRule {
  withdraw: Withdraw
  withdrawId: number
}

@Component({
  components: {
    TextInput,
    Button,
    Loading,
    ModalConfirm,
    ModalSuccess,
  },
})
export default class FundsWithdrawal extends Vue {
  controller = controller
  modalConfirmVisible = false
  modalSuccessVisible = false
  currentPeriod = ''
  withdrawId = ''

  form: Withdraw = {
    minPayoutAmount: 0,
    minSaldo: 0,
    payoutFee: 0,
  }

  created(): void {
    this.currentPeriod = LocalStorage.getLocalStorage('selectedPeriod')
    this.fetchData()
  }

  get isFormChange(): boolean {
    const originalData = controller.dataPeriodRule.withdraw

    if (this.form.minPayoutAmount !== originalData?.minPayoutAmount) {
      return false
    } else if (this.form.minSaldo !== originalData?.minSaldo) {
      return false
    } else if (this.form.payoutFee !== originalData?.payoutFee) {
      return false
    }

    return true
  }

  private fetchData(): void {
    controller.getDetail({
      id: this.$route.params.periodId,
      page: parseInt(<string>this.$route.params.page),
    })
  }

  private onChangeCurrency(
    e: number,
    formField: 'minPayoutAmount' | 'minSaldo' | 'payoutFee'
  ): void {
    let newValue = 0
    newValue = e

    this.form[formField] = newValue
    controller.setIsChanged(true)
  }

  private onSubmit(): void {
    const payload = {
      id: this.withdrawId,
      rules: {
        min_payout_amount: this.form.minPayoutAmount,
        min_saldo: this.form.minSaldo,
        payout_fee: this.form.payoutFee,
      },
    }
    controller.updatePeriodWithdraw(payload)
  }

  @Watch('controller.dataPeriodRule')
  setDataPeriodRule(data: PeriodRule): void {
    const withdraw = data.withdraw
    if (data.withdrawId) {
      this.withdrawId = String(data.withdrawId)
    }
    this.form.minPayoutAmount = withdraw?.minPayoutAmount
    this.form.minSaldo = withdraw?.minSaldo
    this.form.payoutFee = withdraw?.payoutFee
  }

  @Watch('controller.isEditPeriodWithdrawSuccess')
  setEditWithdrawSuccess(data: boolean): void {
    if (data) {
      this.modalConfirmVisible = false
      this.modalSuccessVisible = true
      this.fetchData()
      controller.setIsChanged(false)
      controller.setIsEditPeriodWithdrawSuccess(false)
    }
  }
}
